import { forwardRef, useImperativeHandle } from 'react';
import { useUserPreferences } from '../../hooks/useUserPreferences';

export type UserPreferencesProps = {
    useUserPreferencesProps: Parameters<typeof useUserPreferences>[0];
};

export type UserPreferencesRef = {
    getUserPreferences: ReturnType<typeof useUserPreferences>['getUserPreferences'];
    setUserPreferences: ReturnType<typeof useUserPreferences>['setUserPreferences'];
};

export const UserPreferences = forwardRef<UserPreferencesRef, UserPreferencesProps>(
    ({ useUserPreferencesProps }, ref) => {
        const { getUserPreferences, setUserPreferences } = useUserPreferences(useUserPreferencesProps);

        useImperativeHandle(
            ref,
            () => ({
                getUserPreferences,
                setUserPreferences,
            }),
            [getUserPreferences, setUserPreferences],
        );

        return null;
    },
);
