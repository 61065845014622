import { AgGrid, ETFCard, Layout, Utils } from '@cfra-nextgen-frontend/shared';
import { AgGridCard, AgGirdExportButton } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCard';
import { CardWithAgGridTopPanel } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/CardWithAgGridTopPanel';
import { getCompanyDetailsLinkRenderer } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/renderers';
import { BreadcrumbConfig, ColumnDef } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/types';
import { ChartTabsProps } from '@cfra-nextgen-frontend/shared/src/components/Chart/ChartTabs';
import { NoInformationAvailable } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import { PageWithBreadcrumbsInHeaderContext } from '@cfra-nextgen-frontend/shared/src/components/PageWithBreadcrumbsInHeader/PageWithBreadcrumbsInHeaderContext';
import {
    Categories,
    MarketTrendsDateRanges,
    ValueTypes,
    categoriesToAggregateField,
    categoriesToResponceCategoryField,
    dateRangeToFlowToAssetRatioDataPoint,
    dateRangeToNetFlowsDataPoint,
    dateRangeToReturnSplitAndDividendDataPoint,
    marketTrendsDateRangesToDisplayString,
    valuesTypesToExcelNumberFormat,
} from '@cfra-nextgen-frontend/shared/src/utils';
import { Box } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { exportAgGrid, exportSSRAgGrid } from 'components/excelExport/export';
import { forwardRef, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { useLocation } from 'react-router-dom';
import { getAggregationFlows, getAggregationPerformance, getKeyAssetClassesData } from '../api/market';
import { MarketTrend, MarketTrendsDetailsProps } from '../types/market';
import {
    ETFFlowsToAssetThemesAndFactorsData,
    ETFPerformanceThemesAndFactorsData,
    KeyAssetClassesData,
} from '../types/research';
import {
    tickerDisplayName as keyAssetClassesTickerDisplayName,
    tickerExchanges as keyAssetClassesTickerExchanges,
} from './charts/KeyAssetClasses';
import {
    tickerDisplayName as SectorPerformanceTickerDisplayName,
    tickerExchanges as SectorPerformanceTickerExchanges,
} from './charts/SectorPerformance';
import { prefetchData, prefetchFunctionProps } from './charts/shared/utils';
import { getDrillDownRenderer, getEtfsListOverlayRenderer } from './shared/renderers';
import { DrillDownProps } from './shared/types';
import { getAsOfDateMax, getAsOfDatePath, getMinWidthForHeader, getNextLevelCategory } from './shared/utils';

export type MarketTrendsDetailsLocationState = {
    drillDownProps: DrillDownProps;
    selectedDateRange: MarketTrendsDateRanges;
    selectedCategory: Categories;
};

export const MarketTrendsDetails = forwardRef<React.ReactNode, MarketTrendsDetailsProps>(({ label }, ref) => {
    const { state }: { state: MarketTrendsDetailsLocationState } = useLocation();

    const [drillDownProps, setDrillDownProps] = useState<DrillDownProps | null>(
        state && state.drillDownProps ? state.drillDownProps : null,
    );

    const [selectedDateRange, setSelectedDateRange] = useState<MarketTrendsDateRanges>(
        state && state.selectedDateRange ? state.selectedDateRange : MarketTrendsDateRanges.ThreeMonth,
    );
    const [selectedCategory, setSelectedCategory] = useState<Categories>(
        state && state.selectedDateRange ? state.selectedCategory : Categories.CategoryOne,
    );

    let dateRangesPanelConfig: Layout.ETFButtonsPannelButtonProps[] | undefined = undefined;
    let categoriesPanelConfig: ChartTabsProps | undefined = undefined;

    const gridRef = useRef<AgGridReact>(null);
    const { setRightSideSlotContent } = useContext(PageWithBreadcrumbsInHeaderContext);

    useEffect(() => {
        setRightSideSlotContent(
            <Box sx={{ paddingTop: '42px', paddingRight: '28px' }}>
                <AgGirdExportButton
                    label={label}
                    exportRef={gridRef}
                    excelExportAsOfDateField={getAsOfDatePath(label)}
                    excelExportUseMaxAsOfDate={getAsOfDateMax(label)}
                    dateRangesPanelConfig={dateRangesPanelConfig}
                    selectedCategory={selectedCategory}
                    exportAgGrid={exportAgGrid}
                    exportSSRAgGrid={exportSSRAgGrid}
                />
            </Box>,
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRangesPanelConfig, label, selectedCategory, setRightSideSlotContent, gridRef.current]);

    let columnDefs: Array<ColumnDef> = [];
    let result: UseQueryResult<{ data: any[] }>;

    const etfsListOverlayRenderer = useMemo(
        () => getEtfsListOverlayRenderer(label, selectedDateRange, selectedCategory, drillDownProps),
        [label, selectedDateRange, selectedCategory, drillDownProps],
    );
    const companyDetailsLinkRenderer = getCompanyDetailsLinkRenderer({
        cfraIdPath: [MarketTrend.KeyAssetClassesDetails, MarketTrend.SectorPerformanceDetails].includes(label)
            ? 'id'
            : 'cfra_id',
        cardName: label,
        categoryLevel: selectedCategory,
        dateRange: selectedDateRange,
    });
    const drillDownRenderer = getDrillDownRenderer({
        drillDownProps,
        setDrillDownProps,
        setSelectedCategory,
        label,
        selectedDateRange,
        selectedCategory,
    });

    switch (label) {
        case MarketTrend.FlowsToAssetsDetails:
        case MarketTrend.PerformanceDetails:
            const assetClassColumn = {
                headerName: Categories.AssetClass,
                field: 'asset_class',
                cellRenderer: drillDownRenderer,
            };
            const cfraCategoryColumn = {
                headerName: Categories.CategoryOne,
                field: 'cfra_level_1',
                cellRenderer: drillDownRenderer,
            };
            const cfraSubCategoryColumn = {
                headerName: Categories.CategoryTwo,
                field: 'level_2_name',
                cellRenderer: etfsListOverlayRenderer,
            };

            switch (selectedCategory) {
                case Categories.AssetClass:
                    columnDefs.push(assetClassColumn);
                    break;
                case Categories.CategoryOne:
                    columnDefs = columnDefs.concat([assetClassColumn, cfraCategoryColumn]);
                    break;
                case Categories.CategoryTwo:
                    columnDefs = columnDefs.concat([assetClassColumn, cfraCategoryColumn, cfraSubCategoryColumn]);
                    break;
            }

            const registerAction = (action: string) =>
                globalThis.analytics?.registerAction?.({
                    action: action,
                    cardName: label,
                    dateRange: selectedDateRange,
                    selectedCategory: drillDownProps ? 'no categories selected' : selectedCategory,
                });

            dateRangesPanelConfig = Object.values(MarketTrendsDateRanges).map((dateRange) => ({
                name: dateRange,
                callback: () => {
                    registerAction(`date range selection : ${dateRange}`);
                    setSelectedDateRange(dateRange as MarketTrendsDateRanges);
                },
                disabled: false,
                isDefault: dateRange === selectedDateRange,
            }));

            categoriesPanelConfig = {
                tabs: Object.values(Categories),
                currentActiveTab: drillDownProps ? false : Object.values(Categories).indexOf(selectedCategory),
                handleTabChange: (event: React.SyntheticEvent, newValue: number) => {
                    if (drillDownProps) setDrillDownProps(null);
                    const newCategory = Object.values(Categories)[newValue];
                    registerAction(`category selection : ${newCategory}`);
                    setSelectedCategory(newCategory);
                },
            };

            break;
    }

    const performanceColumnsDefs: Array<ColumnDef> = [
        {
            headerName: 'YTD Return',
            field: 'performance.split_and_dividend_ytd_return',
            valueFormatter: AgGrid.getAgGridFormatter(Utils.ValueTypes.Percentage),
            type: 'rightAligned',
            excelExportNumberFormat: valuesTypesToExcelNumberFormat[ValueTypes.Percentage],
        },
        {
            headerName: '1 Month Return',
            field: 'performance.month_1_return',
            valueFormatter: AgGrid.getAgGridFormatter(Utils.ValueTypes.Percentage),
            type: 'rightAligned',
            excelExportNumberFormat: valuesTypesToExcelNumberFormat[ValueTypes.Percentage],
        },
        {
            headerName: '3 Month Return',
            field: 'performance.month_3_return',
            valueFormatter: AgGrid.getAgGridFormatter(Utils.ValueTypes.Percentage),
            type: 'rightAligned',
            excelExportNumberFormat: valuesTypesToExcelNumberFormat[ValueTypes.Percentage],
        },
        {
            headerName: '1 Year Return',
            field: 'performance.return_split_and_dividend_one_year',
            valueFormatter: AgGrid.getAgGridFormatter(Utils.ValueTypes.Percentage),
            type: 'rightAligned',
            excelExportNumberFormat: valuesTypesToExcelNumberFormat[ValueTypes.Percentage],
        },
    ];

    const flowsColumnsDefs: Array<ColumnDef> = [
        {
            headerName: 'YTD Flows ($M)',
            field: 'performance.net_flows_ytd',
            valueFormatter: AgGrid.getAgGridFormatter(Utils.ValueTypes.MillionsWithForceScale),
            type: 'rightAligned',
            excelExportNumberFormat: valuesTypesToExcelNumberFormat[ValueTypes.MillionsWithForceScale],
        },
        {
            headerName: '1 Month Flows ($M)',
            field: 'performance.net_flows_one_month',
            valueFormatter: AgGrid.getAgGridFormatter(Utils.ValueTypes.MillionsWithForceScale),
            type: 'rightAligned',
            excelExportNumberFormat: valuesTypesToExcelNumberFormat[ValueTypes.MillionsWithForceScale],
        },
        {
            headerName: '3 Month Flows ($M)',
            field: 'performance.net_flows_three_month',
            valueFormatter: AgGrid.getAgGridFormatter(Utils.ValueTypes.MillionsWithForceScale),
            type: 'rightAligned',
            excelExportNumberFormat: valuesTypesToExcelNumberFormat[ValueTypes.MillionsWithForceScale],
        },
        {
            headerName: '1 Year Flows ($M)',
            field: 'performance.net_flows_one_year',
            valueFormatter: AgGrid.getAgGridFormatter(Utils.ValueTypes.MillionsWithForceScale),
            type: 'rightAligned',
            excelExportNumberFormat: valuesTypesToExcelNumberFormat[ValueTypes.MillionsWithForceScale],
        },
    ];

    const etfNameColumnDef = {
        headerName: 'ETF Name',
        field: 'composite_name',
        cellRenderer: companyDetailsLinkRenderer,
    };

    const tickerColumnDef = {
        headerName: 'Ticker',
        field: 'composite_ticker',
        cellRenderer: companyDetailsLinkRenderer,
    };

    const numberOfEtfsColumnDef = {
        headerName: 'Number of ETFs',
        field: 'etf_count',
        type: 'rightAligned',
        flex: 1,
        cellRenderer: etfsListOverlayRenderer,
        excelExportNumberFormat: valuesTypesToExcelNumberFormat[ValueTypes.Integer],
    };

    switch (label) {
        case MarketTrend.FlowsToAssetsDetails: {
            result = getAggregationFlows({
                sortDirection: 'desc',
                top: 1000,
                orderBy: dateRangeToFlowToAssetRatioDataPoint[selectedDateRange],
                aggregateBy: categoriesToAggregateField[selectedCategory],
            }) as UseQueryResult<{ data: ETFFlowsToAssetThemesAndFactorsData[] }>;

            columnDefs = columnDefs.concat([
                {
                    headerName: `${marketTrendsDateRangesToDisplayString[selectedDateRange]} Flows ($M)`,
                    field: dateRangeToNetFlowsDataPoint[selectedDateRange],
                    valueFormatter: AgGrid.getAgGridFormatter(Utils.ValueTypes.MillionsWithForceScale),
                    type: 'rightAligned',
                    excelExportNumberFormat: valuesTypesToExcelNumberFormat[ValueTypes.MillionsWithForceScale],
                },
                {
                    headerName: 'Total Assets ($M)',
                    field: 'total_net_assets',
                    valueFormatter: AgGrid.getAgGridFormatter(Utils.ValueTypes.MillionsWithForceScale),
                    type: 'rightAligned',
                    excelExportNumberFormat: valuesTypesToExcelNumberFormat[ValueTypes.MillionsWithForceScale],
                },
                {
                    headerName: `${marketTrendsDateRangesToDisplayString[selectedDateRange]} Flows to Asset`,
                    field: dateRangeToFlowToAssetRatioDataPoint[selectedDateRange],
                    sort: 'desc',
                    valueFormatter: AgGrid.getAgGridFormatter(Utils.ValueTypes.Percentage),
                    type: 'rightAligned',
                    excelExportNumberFormat: valuesTypesToExcelNumberFormat[ValueTypes.Percentage],
                },
                numberOfEtfsColumnDef,
            ]);
            break;
        }
        case MarketTrend.PerformanceDetails: {
            result = getAggregationPerformance({
                sortDirection: 'desc',
                top: 1000,
                orderBy: dateRangeToReturnSplitAndDividendDataPoint[selectedDateRange],
                aggregateBy: categoriesToAggregateField[selectedCategory],
            }) as UseQueryResult<{ data: ETFPerformanceThemesAndFactorsData[] }>;

            columnDefs = columnDefs.concat([
                etfNameColumnDef,
                tickerColumnDef,
                {
                    headerName: `${marketTrendsDateRangesToDisplayString[selectedDateRange]} Return`,
                    field: dateRangeToReturnSplitAndDividendDataPoint[selectedDateRange],
                    sort: 'desc',
                    valueFormatter: AgGrid.getAgGridFormatter(Utils.ValueTypes.Percentage),
                    type: 'rightAligned',
                    excelExportNumberFormat: valuesTypesToExcelNumberFormat[ValueTypes.Percentage],
                },
                numberOfEtfsColumnDef,
            ]);
            break;
        }
        case MarketTrend.KeyAssetClassesDetails: {
            result = getKeyAssetClassesData({
                tickerExchanges: keyAssetClassesTickerExchanges,
            });

            columnDefs = [
                {
                    headerName: 'Asset Class',
                    field: 'asset_class',
                    sort: 'asc',
                },
                etfNameColumnDef,
                tickerColumnDef,
            ];
            columnDefs = columnDefs.concat(performanceColumnsDefs);
            columnDefs = columnDefs.concat(flowsColumnsDefs);
            break;
        }
        case MarketTrend.SectorPerformanceDetails: {
            result = getKeyAssetClassesData({
                tickerExchanges: SectorPerformanceTickerExchanges,
            });

            columnDefs = [{ headerName: 'Sector', field: 'sector', sort: 'asc' }, etfNameColumnDef, tickerColumnDef];
            columnDefs = columnDefs.concat(performanceColumnsDefs);
            columnDefs = columnDefs.concat(flowsColumnsDefs);
            break;
        }
        default: {
            throw new Error(`${label} doesn't exist.`);
        }
    }

    let breadcrumbsConfig: BreadcrumbConfig[] | undefined = undefined;

    const containerStyles = { paddingTop: '36px' };

    if (result.isLoading) {
        return (
            <CardWithAgGridTopPanel
                containerStyles={containerStyles}
                dateRangesPanelConfig={dateRangesPanelConfig}
                categoriesPanelConfig={categoriesPanelConfig}
                breadcrumbsConfig={breadcrumbsConfig}>
                <ETFCard.ETFCard isLoading={result.isLoading} />
            </CardWithAgGridTopPanel>
        );
    }

    if (!result.data) {
        return (
            <CardWithAgGridTopPanel
                containerStyles={containerStyles}
                dateRangesPanelConfig={dateRangesPanelConfig}
                categoriesPanelConfig={categoriesPanelConfig}
                breadcrumbsConfig={breadcrumbsConfig}>
                <NoInformationAvailable sx={{ width: '100%' }} />
            </CardWithAgGridTopPanel>
        );
    }

    let rowsData: Array<any>;

    switch (label) {
        case MarketTrend.KeyAssetClassesDetails: {
            rowsData = (result.data.data as KeyAssetClassesData[]).map((row) => ({
                ...row,
                asset_class: keyAssetClassesTickerDisplayName[row.composite_ticker],
            }));
            break;
        }
        case MarketTrend.SectorPerformanceDetails: {
            rowsData = (result.data.data as KeyAssetClassesData[]).map((row) => ({
                ...row,
                sector: SectorPerformanceTickerDisplayName[row.composite_ticker],
            }));
            break;
        }
        default:
            rowsData = result.data.data;
    }

    if (drillDownProps) {
        rowsData = rowsData.filter((row) => {
            if (drillDownProps.categoryOne) {
                return row[categoriesToResponceCategoryField[Categories.CategoryOne]] === drillDownProps.categoryOne;
            }
            if (drillDownProps.assetClass) {
                return row[categoriesToResponceCategoryField[Categories.AssetClass]] === drillDownProps.assetClass;
            }
            throw new Error("Can't find categoryOne or assetClass value for the drill down.");
        });

        const categoryBreadcrumbText =
            (!drillDownProps.categoryOne ? `${Categories.AssetClass}: ` : '') + drillDownProps.assetClass;

        breadcrumbsConfig = [
            {
                text: Categories.AssetClass,
                callback: () => {
                    globalThis.analytics?.registerAction?.({
                        action: `ag grid breadcrumbs : ${Categories.AssetClass}`,
                        cardName: label,
                        dateRange: selectedDateRange,
                    });
                    setDrillDownProps(null);
                    setSelectedCategory(Categories.AssetClass);
                },
            },
            {
                text: categoryBreadcrumbText,
                callback: () => {
                    globalThis.analytics?.registerAction?.({
                        action: `ag grid breadcrumbs : ${categoryBreadcrumbText}`,
                        cardName: label,
                        dateRange: selectedDateRange,
                    });
                    setDrillDownProps({
                        categoryOne: '',
                        assetClass: drillDownProps.assetClass,
                    });
                    setSelectedCategory(getNextLevelCategory(Categories.AssetClass));
                },
            },
        ];

        if (drillDownProps.categoryOne) {
            breadcrumbsConfig.push({
                text: `${Categories.CategoryOne}: ${drillDownProps.categoryOne}`,
            });
        }
    }

    switch (label) {
        case MarketTrend.FlowsToAssetsDetails: {
            prefetchData({
                selectedCategory,
                selectedDateRange,
                prefetchFunction: ({ category, dateRange }: prefetchFunctionProps) =>
                    getAggregationFlows({
                        sortDirection: 'desc',
                        orderBy: dateRangeToFlowToAssetRatioDataPoint[dateRange],
                        aggregateBy: categoriesToAggregateField[category],
                        top: 1000,
                        usePrefetchQuery: true,
                    }),
            });
            break;
        }
        case MarketTrend.PerformanceDetails: {
            prefetchData({
                selectedCategory,
                selectedDateRange,
                prefetchFunction: ({ category, dateRange }: prefetchFunctionProps) =>
                    getAggregationPerformance({
                        sortDirection: 'desc',
                        orderBy: dateRangeToReturnSplitAndDividendDataPoint[dateRange],
                        aggregateBy: categoriesToAggregateField[category],
                        top: 1000,
                        usePrefetchQuery: true,
                    }),
            });
            break;
        }
    }

    return (
        <>
            {rowsData.length > 0 ? (
                <AgGridCard
                    ref={gridRef}
                    containerStyles={containerStyles}
                    columnDefs={columnDefs}
                    rowsData={rowsData}
                    dateRangesPanelConfig={dateRangesPanelConfig}
                    categoriesPanelConfig={categoriesPanelConfig}
                    breadcrumbsConfig={breadcrumbsConfig}
                    customFlexibleColumns={[Categories.CategoryOne, Categories.CategoryTwo, 'ETF Name']}
                    getResizableMinWidthForColumn={getMinWidthForHeader}
                    defaultMaxWidth={350}
                />
            ) : (
                <CardWithAgGridTopPanel
                    containerStyles={containerStyles}
                    dateRangesPanelConfig={dateRangesPanelConfig}
                    categoriesPanelConfig={categoriesPanelConfig}
                    breadcrumbsConfig={breadcrumbsConfig}>
                    <NoInformationAvailable sx={{ width: '100%' }} />
                </CardWithAgGridTopPanel>
            )}
        </>
    );
});
